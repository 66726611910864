import React from "react"
import DownloadForm from "src/components/DownloadForm/DownloadForm"
import Footer from "src/components/Footer/Footer"
import "./SectionFlyingApps.scss"

const SectionFlyingApps = ({ t }) => (
  <div className="flying-apps">
    <div className="container container--lg flying-apps-container">
      <div className="content">
        <h1 className="hero-text mb-4 text-white">{t('home.ready.title')}</h1>
        <p dangerouslySetInnerHTML={{ __html: t("home.ready.desc") }} />
        <DownloadForm t={t}></DownloadForm>
      </div>

      <img
        src={require("src/assets/flying-apps.png")}
        className="flying-apps-img"
        alt=""
      />

      <Footer />
    </div>

    <img
      src={require("src/assets/dark-stacks.svg")}
      alt=""
      className="dark-stacks"
    />
  </div>
)

export default SectionFlyingApps
