import React, { useState, useEffect, useRef } from "react"
import DownloadForm from "src/components/DownloadForm/DownloadForm"
import GifTip from "src/components/GifTip/GifTip"
import "./SectionApps.scss"

const SectionApps = ({ t, location }) => {
  const [isStacksElVisible, setIsStacksElVisible] = useState(false)
  const stacksEl = useRef(null)
  const isKa = location.pathname.startsWith('/ka')

  useEffect(() => {

    const setObserver = () => {
      var options = {
        threshold: [0.2],
      }

      var observer = new IntersectionObserver(e => {
        if (e[0].isIntersecting) {
          setIsStacksElVisible(true)
          observer.unobserve(stacksEl.current)
        }
      }, options)
      observer.observe(stacksEl.current)
    }

    setObserver()
  }, [])

  return (
    <section className="stack-apps" ref={stacksEl}>
      <div className="container container--lg">
        <div className="row align-items-center h-100">
          <div className="col col-12 col-md-5 px-sm-3 px-5">
            <h1 className="hero-text mb-4 text-white">
              {
                isKa ?
                <a style={{color: '#FFF'}} href="https://www.youtube.com/watch?v=LAXN4WlGBik&feature=youtu.be&t=58" target="_blank">
                  <GifTip url="https://i.imgur.com/DS5QoPt.gif">
                    {t('home.privacy.title')}
                  </GifTip>
                </a>
                :
                <span>{t('home.privacy.title')}</span>
              }
            </h1>
            <p>
              {t('home.privacy.desc')}
            </p>

            <DownloadForm t={t}></DownloadForm>
          </div>
          <div className="col col-12 col-md-7 h-100 mt-5 mt-md-0 px-sm-3 px-5">
            <div className={`apps apps--${isStacksElVisible ? "slided" : ""}`}>
              <div className="app" />
              <div className="app" />
              <div className="app" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionApps
