import React from 'react'
import SectionApps from 'src/components/SectionApps/SectionApps'
import SectionFeatures from 'src/components/SectionFeatures/SectionFeatures'
import SectionHero from 'src/components/SectionHero/SectionHero'
import SectionMac from 'src/components/SectionMac/SectionMac'
import SEO from 'src/components/seo'
import SectionFlyingApps from 'src/components/SectionFlyingApps/SectionFlyingApps'
import SectionTestimonials from 'src/components/SectionTestimonials/SectionTestimonials.js'
import useTranslation from 'src/hooks/useTranslation'

import './home.scss'

const Homepage = ({ location, lang }) => {
  const { t } = useTranslation({ location })
  const isKa = location.pathname.startsWith('/ka')

  return (
    <div className="home-page">
      {isKa ? (
        <SEO
          title={t('seo.title')}
          description={t('seo.desc')}
          image="https://i.imgur.com/nc1W13b.png"
        />
      ) : (
        <SEO
          title={t('seo.title')}
          description={t('seo.desc')}
          image="https://i.imgur.com/YaQdSi5.png"
        />
      )}

      <SectionHero t={t} lang={lang} />
      <SectionFeatures t={t} />
      <SectionApps location={location} t={t} />
      <SectionMac t={t} />
      {/* <SectionAchievements t={t} > */}
      <SectionTestimonials t={t} />
      <SectionFlyingApps t={t} />
    </div>
  )
}

export default Homepage
