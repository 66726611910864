import React, { useEffect, useState } from "react"
import axios from "axios"

import "./DownloadStats.scss"

const serverUrl = process.env.GATSBY_SERVER_URL

const numberWithCommas = (x = 0) => {
  var parts = x.toString().split(".")
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  return parts.join(".")
}

const countryCodeToName = (code) => {
  const countryNames = {
    JP: "Japan",
    KR: "South Korea",
    US: "United States",
    GE: "Georgia",
    NL: "Netherlands",
  }
  return countryNames[code] || code
}

const country = country => {
  return (
    <div className="download-stats__country" key={country._id}>
      <div className="download-stats__country-name">
        <div className="download-stats__country-qtty">{numberWithCommas(country.total)}</div>
        <span className="ml-auto">{countryCodeToName(country.country)}</span>
      </div>
      <div className="download-stats__country-progress">
        <div className="download-stats__country-progress-complete" style={{width: country.total / 5000 * 100 + '%'}}></div>
      </div>
    </div>
  )
}

const DownloadStats = () => {
  const [myCountry, setMyCountry] = useState({})
  const [countries, setCountries] = useState([])
  const [total, setTotal] = useState(0)
  const [isInTopThree, setIsInTopThree] = useState(false)

  useEffect(() => {
    const fetchCountries = async () => {
      const { data } = await axios.get(`${serverUrl}/download/counter`)
      if (data && data.data) {
        setCountries(data.data.countries)
        setMyCountry(data.data.me || {})
        setTotal(data.data.total)
        setIsInTopThree(countries.find(c => c.country === myCountry.country))
      }
    }
    fetchCountries()
  }, [])

  if (myCountry.country !== 'GE') {
    return <div />
  }

  return (
    <>
      {countries && countries.length &&
        <div className="download-stats">
          <div className="download-stats__title mb-3">
            Total Downloads <br/>
            <b>
              {numberWithCommas(total)}
            </b>
          </div>
          {countries.map(c => country(c))}
          <div className="download-stats__my">
            {!isInTopThree && country(myCountry)}
          </div>
        </div>
      }
    </>
  )
}

export default DownloadStats
