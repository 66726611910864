import React, { useState, useEffect } from "react"
import VideoModal from "src/components/VideoModal/VideoModal"
import DownloadForm from "src/components/DownloadForm/DownloadForm"
import iMac from "src/assets/imac-pro-stand.svg"
import winStand from "src/assets/win-stand.svg"
import DownloadStats from "src/components/DownloadStats/DownloadStats"
import "./SectionHero.scss"

import AppleLogo from "src/assets/brand-logos/apple.svg"
import TwitterLogo from "src/assets/brand-logos/twitter.svg"
import NikeLogo from "src/assets/brand-logos/nike.svg"
import UberLogo from "src/assets/brand-logos/uber.svg"
import AtlassianLogo from "src/assets/brand-logos/atlassian.svg"

import Video1 from "src/assets/videos/mobile-promo.mp4"
import Video2 from "src/assets/videos/mobile-promo.webm"

import { IoIosPlayCircle } from "react-icons/io"

const SectionHero = ({ t, lang }) => {
  const [isVideoModalVisible, setVideoModalVisible] = useState(false)
  const [os, setOs] = useState()

  const toggleVideoModal = () => {
    setVideoModalVisible(!isVideoModalVisible)
  }

  useEffect(() => {
    import("src/utils/helpers").then(({ getOS }) => {
      setOs(getOS())
    })
  }, [])

  return (
    <section className="section-hero">
      <div className="d-none d-lg-block">
        {lang === "ka" && <DownloadStats />}
      </div>
      <div className="container hero text-center">
        <div className="hero-content m-auto">
          <h1 className="text-white" style={{ fontWeight: 800 }}>
            {t("home.headline2")}
          </h1>

          <p dangerouslySetInnerHTML={{ __html: t("home.headline1") }} />

          <div className="mt-5">
            <DownloadForm
              t={t}
              className="mr-1"
              style={{ display: "inline-block" }}
            />
          </div>

          <button
            className="watch-video d-flex mx-auto"
            onClick={toggleVideoModal}
          >
            <IoIosPlayCircle style={{ marginBottom: 1 }} className="mr-2" />
            {t("home.watch_intro")}
          </button>
        </div>
      </div>

      <div className="container video text-center mt-1 mt-md-2">
        <div className="mac mx-auto mb-">
          <div className="mac-screen">
            <div className="mac-video">
              <video autoPlay muted loop>
                <source src={Video1} type="video/mp4" />
                <source src={Video2} type="video/webm" />
              </video>
            </div>
            {os === "Mac OS" ? (
              <img src={iMac} className="mac-stand" alt="" />
            ) : (
              <img src={winStand} className="mac-stand" alt="" />
            )}
          </div>
        </div>
      </div>

      <div className="brand-logos">
        <div className="brand-logos__items">
          <img src={AppleLogo} alt="" className="brand-logos__logo" />
          <img src={TwitterLogo} alt="" className="brand-logos__logo" />
          <img src={AtlassianLogo} alt="" className="brand-logos__logo" />
          <img src={UberLogo} alt="" className="brand-logos__logo" />
          <img src={NikeLogo} alt="" className="brand-logos__logo" />
        </div>
        <h1 className="brand-logos__headline">
          {" "}
          Stackers can be found in these companies too
        </h1>
      </div>

      {isVideoModalVisible && <VideoModal close={toggleVideoModal} />}
    </section>
  )
}

export default SectionHero
