import React from "react"
import DownloadForm from "src/components/DownloadForm/DownloadForm"
import "./SectionMac.scss"

import { IoMdCheckmark } from "react-icons/io"

const SectionMac = ({ t }) => (
  <section className="section-mac">
    <div className="mx-0 mx-md-5 bg-white rounded-top">
      <div className="row align-items-center">
        <div className="macbook-wrapper col col-12 col-md-6">
          <div className="macbook">
            {/* <video className="macbook-video"
                  autoplay
                  loop="true">
              <source src="../../assets/videos/videoplayback.mp4" type="video/mp4">
              <source src="myVideo.webm" type="video/webm">
            </video> */}
            <img
              className="macbook-laptop"
              src={require("src/assets/macbook/macbook.png")}
              alt=""
            />
            <img
              className="macbook-shadow"
              src={require("src/assets/macbook/macbook-shadow.png")}
              alt=""
            />
          </div>
        </div>
        <div className="col col-12 col-md-6 px-sm-5 px-3 mt-sm-0 mt-4">
          <div className="px-4">
            <h1 className="hero-text mb-4">
              {t('home.pro.title')}
            </h1>
            <div>
            {t('home.pro.desc')}
            </div>
            <br />
            <DownloadForm t={t}></DownloadForm>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default SectionMac
