import React, { useState } from "react"
import StackWindow from "src/components/SectionFeatures/StackWindow"
import "./SectionFeatures.scss"

const SectionFeatures = ({ t }) => {
  const [activeFeature, setActiveFeature] = useState({ activeState: null })

  const features = [
    {
      id: 2,
      name: t('home.features.feat1.title'),
      text: t('home.features.feat1.desc'),
      icon: "meditate.svg",
      activeState: "web-apps",
    },
    {
      id: 1,
      name: t('home.features.feat2.title'),
      text: t('home.features.feat2.desc'),
      icon: "four-squares.svg",
      activeState: "stacks",
    },
    {
      id: 3,
      name: t('home.features.feat3.title'),
      text: t('home.features.feat3.desc'),
      icon: "turn-notifications-on-button.svg",
      activeState: "notification",
    },
  ]

  const activateFeature = feature => () => {
    setActiveFeature(feature)
  }
  const deActivateFeature = () => {
    setActiveFeature(null)
  }

  const activeFeatureId = activeFeature && activeFeature.id

  return (
    <section className="section-features">
      <div className="bg-white mx-0 mx-md-5 rounded">
        <div className="container container--lg">
          <div className="row align-items-center">
            <div className="col col-12 col-md-6 pr-sm-5 pr-4 pl-sm-3 pl-4">
              <h1 className="mb-5">{t('home.features.work_more')}</h1>

              <div className="features">
                {features.map(feature => (
                  <div
                    tabIndex="0"
                    role="button"
                    key={feature.id.toString()}
                    className={`feature ${
                      feature.id === activeFeatureId ? "active" : ""
                    }`}
                    onMouseEnter={activateFeature(feature)}
                    onMouseLeave={deActivateFeature}
                  >
                    <div className="feature-icon">
                      <img
                        src={require(`src/assets/icons/${feature.icon}`)}
                        alt=""
                      />
                    </div>
                    <div className="feature-content ml-4">
                      <h4 className="feature__title">{feature.name}</h4>
                      <p className="feature__content m-0">{feature.text}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col col-12 col-md-6 d-none d-sm-block">
              <StackWindow
                style={{ margin: "auto" }}
                activeStateClass={
                  activeFeature ? activeFeature.activeState : null
                }
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionFeatures
