import React, { useState, useEffect, useRef } from "react"
import "./StackWindow.scss"

const StackWindow = ({ activeStateClass }) => {
  const [isWindowVisible, setIsWindowVisible] = useState(false)
  const windowEl = useRef(null)

  useEffect(() => {
    const setObserver = () => {
      var options = {
        threshold: [0.4],
      }
      var observer = new IntersectionObserver(e => {
        if (e[0].isIntersecting) {
          setIsWindowVisible(true)
          observer.unobserve(windowEl.current)
        }
      }, options)
      observer.observe(windowEl.current)
    }

    setObserver()
  }, [])

  return (
    <div className="viewport" ref={windowEl}>
      <div
        className={`window window--active-${activeStateClass} ${
          isWindowVisible ? "window--3d" : ""
        }`}
      >
        <div className="stack-notification"></div>
        <div className="stack stack--1"></div>
        <div className="stack stack--2"></div>
        <div className="stack stack--3"></div>
        <div className="stack stack--1 stack--shadow"></div>
        <div className="stack stack--2 stack--shadow"></div>
        <div className="stack stack--3 stack--shadow"></div>
        <div className="web-app web-app--1"></div>
        <div className="web-app web-app--2"></div>
        <div className="web-app web-app--1 web-app--shadow"></div>
        <div className="web-app web-app--2 web-app--shadow"></div>
        <img
          src={require("src/assets/mojave-night.jpg")}
          className="window-bg"
          alt=""
        />
        
      </div>
    </div>
  )
}

export default StackWindow
