/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef } from "react"
// import Img from "gatsby-image"
import "./GifTip.scss"

const GifTip = ({ children, url, size }) => {
  const [isVisible, setIsVisible] = useState(false)
  const imgEl = useRef(null)

  const onMouseEnter = () => {
    setIsVisible(true)
  }

  const onMouseLeave = () => {
    setIsVisible(false)
  }

  const onMouseMove = (e) => {
    imgEl.current.style.top = `${e.clientY}px`
    imgEl.current.style.left = `${e.clientX}px`
  }

  return (
    <span
      className={`gif-tip gif-tip--${size}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
    >
      {children}
      {isVisible && <img src={url} alt={'img'} className="gif-tip__img" ref={imgEl} />}
    </span>
  )
}

export default GifTip
