import React, { useState, useEffect } from "react"

import "./SectionTestimonials.scss";

import QuoteIconUp from "src/assets/icons/quote-up.svg"
import QuoteIconDown from "src/assets/icons/quote-down.svg"

const testimonialsArray = [
  {
    name: 'Albert',
    text: 'Well... Everything is relative but this app is definitely awesome!',
    avatar: 'https://www.biography.com/.image/t_share/MTE5NDg0MDU0OTU2OTAxOTAz/albert-einstein-9285408-1-402.jpg'
  },
  {
    name: 'Donald',
    text: 'This is just an amazingly beautiful product! It might probably definitely get bigger.',
    avatar: 'https://www.motherjones.com/wp-content/uploads/2020/04/20200425-trump-face-2000.jpg?w=990'
  },
  {
    name: 'Elon',
    text: 'I have no fu...ing idea what Stack is but if I knew, I would definitely love it!',
    avatar: 'https://www.eteknix.com/wp-content/uploads/2018/09/2-10.jpg'
  },
  {
    name: 'Alien',
    text: 'They just stole the code from us and called it Stack... Losers!',
    avatar: 'https://images0.persgroep.net/rcs/F-l_pZMr5iVy1QYVLL-hhNXkZdQ/diocontent/152800804/_fitwidth/763?appId=93a17a8fd81db0de025c8abd1cca1279&quality=0.8'
  },
]

const testimonialEl = (testimonial) => {
  return (
    <div className="testimonial" key={testimonial.name}>
      <div className="testimonial__popover se-popover">
        <div className="testimonial__content">
          <img className="testimonial__quote" src={QuoteIconUp} alt=""/>
          <span className="testimonial__text">
            {testimonial.text}
          </span>
          <img className="testimonial__quote testimonial__quote--right" src={QuoteIconDown} alt=""/>
        </div>

        <div className="se-popover-triangle se-popover-triangle--right-center"></div>
      </div>

      <div className="testimonial__avatar">
        <img className="testimonial__avatar-img" src={testimonial.avatar} alt=""/>
        <p className="testimonial__author">{testimonial.name}</p>
      </div>
    </div>
  )
}

const Testimonials = ({ t }) => {
  const [activeTestimonialIndex, setActiveTestimonialIndex] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      const newValue = activeTestimonialIndex < testimonialsArray.length - 1 ? activeTestimonialIndex + 1 : 0
      setActiveTestimonialIndex(newValue)
    }, 6000);
  }, [activeTestimonialIndex])

  return (
    <section className="section-testimonials">
      <div className="mx-md-5 mx-0 section-testimonials-wrapper rounded-bottom">
        <div className="row align-items-center">
          <div className="col col-md-6 col-12">
            <h1 className="section-testimonials__title mb-0">
              {t("home.testimonials.heading")}
            </h1>
          </div>
          <div className="col col-md-6 col-12">
            { testimonialsArray.map((testimonial, index) => {
              return index === activeTestimonialIndex && testimonialEl(testimonial)
            }) }
          </div>
        </div>
      </div>
    </section>
  )
}

export default Testimonials
