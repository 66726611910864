import React from "react"
import "./VideoModal.scss"

import { IoMdClose } from "react-icons/io"

const VideoModal = ({ close = () => {} }) => (
  <section className="video-modal">
    <button className="vide-modal-overlay" onClick={close} />
    <div className="video-modal-content">
      <div className="video-modal-header">
        <button className="video-modal-close-btn" onClick={close}>
          <IoMdClose className="close-icon" />
        </button>
      </div>
      <div className="parent">
        <div className="child">
          <iframe
            width="560"
            height="315"
            className="youtube-video"
            src="https://www.youtube.com/embed/rACfm5UBS9o"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Stack Promo"
          ></iframe>
        </div>
      </div>
    </div>
  </section>
)

export default VideoModal
